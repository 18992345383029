import { Maybe } from 'true-myth';
import i18nFr from '../assets/i18n/fr.json';

export class TranslationService {
  public readonly translate: (key: string) => string;

  constructor() {
    this.translate = (key: string): string => Maybe.of(i18nFr[key]).mapOrElse(
      () => key,
      (localizedText: string) => localizedText,
    );
  }
}
