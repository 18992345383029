import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import MenuItem from '@material-ui/core/MenuItem';

// TODO: specifiy type
const LinkMenuItem: React.FC<any> = ({ to, children, ...rest }) => (
  <MenuItem component={Link} to={to} {...rest}>
    {children}
  </MenuItem>
);

LinkMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

export default LinkMenuItem;
