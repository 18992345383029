import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby-theme-material-ui';
import ReactMarkdown from 'markdown-to-jsx';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    marginTop: theme.spacing(1),
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
  },
}));

const Markdown: React.FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {

  const myClasses = useStyles();

  const options = {
    forceBlock: true,
    disableParsingRawHTML: true,
    overrides: {
      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h5',
        },
      },
      h2: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h6',
        },
      },
      h3: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'subtitle1',
        },
      },
      h4: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'caption',
          paragraph: true,
        },
      },
      p: {
        component: ({ ...props }) => (
          <Typography component="p" variant="body2" {...props} style={{ lineHeight: 1.9 }} />
        ),
      },
      li: {
        component: ({ ...props }) => (
          <li className={myClasses.listItem}>
            <Typography component="span" variant="body2" {...props} />
          </li>
        ),
      },
      a: {
        component: ({ ...props }) => (
          <Link to={props.href} className={myClasses.link}>
            {props.children}
          </Link>
        )
      },
      strong: {
        component: ({ ...props }) => (
          <Box component="strong" fontWeight='fontWeightMedium' display='inline'>
            {props.children}
          </Box>
        )
      }
    },
  };

  return (
    <ReactMarkdown options={options} {...props}>
      {props.children}
    </ReactMarkdown>
  );
};

export default Markdown;
