import React, { PropsWithChildren, useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Button, IconButton, Link } from 'gatsby-theme-material-ui';
import Layout, {
  getContent,
  getDrawerSidebar,
  getHeader,
  getInsetFooter,
  getSidebarContent,
  getSidebarTrigger, getSubheader,
  Root,
} from '@mui-treasury/layout';
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItem, ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Toolbar from '@material-ui/core/Toolbar';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import { useRowGutterStyles } from '@mui-treasury/styles/gutter/row';
import FooterItem from './components/footerItem';
import ExternalLink from '../shared-components/externalLink';
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import FooterInfo from './components/footerInfo';
import { Category, CategoryBase, CategorySummary } from '../models/category';
import { TranslationService } from '../services/translationService';
import { eventsServiceContext, translationServiceContext } from '../services/provider';
import HeaderToolbar from './components/headerToolbar';
import { normalizeMoroccanPhoneNumber } from '../utils/helper';
import TeaserMessage from "./components/teaserMessage";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { EventsService } from "../services/eventsService";
import { CategoryGraphQlResponse } from "../../gatsby-node";
import { getCategoryParentsList } from "../utils/general";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const scheme = Layout();

const headerMdHeight = 90;
const subHeaderMdHeight = 35;

scheme.configureHeader((builder) => {
  builder
    .create('header')
    .registerConfig('xs', {
      position: 'sticky',
    })
    .registerConfig('md', {
      position: 'sticky',
      initialHeight: headerMdHeight,
    });
});

scheme.configureSubheader(builder => {
  builder.create('subHeader')
    .registerConfig('xs', {
      position: 'relative',
      initialHeight: subHeaderMdHeight,
      clipped: true
    })
})

scheme.configureEdgeSidebar((builder) => {
  builder.create('primarySidebar', { anchor: 'left' })
    .registerTemporaryConfig('xs', {
      width: 280, // 'auto' is only valid for temporary variant
    });
  builder.hide('primarySidebar', ['md']);
});

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    //borderBottom: `1px solid ${theme.palette.divider}`, /* hsl(210, 32%, 93%) */
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  displayInlineBlock: {
    display: 'inline-block',
  },
  content: {
    backgroundColor: 'white',
  },
  footer: {
    backgroundColor: theme.palette.grey['100'],
    borderTop: `1px solid ${theme.palette.grey.A100}`,
  },
  unstyledUl: {
    listStyleType: 'none',
    paddingLeft: 0
  },
  subHeaderBackground: {
    backgroundColor: theme.palette.grey["600"]
  },
  subHeaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  }
}));

const Header = getHeader(styled);
const SubHeader = getSubheader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const Content = getContent(styled);
const InsetFooter = getInsetFooter(styled);

export default function TopLayout(props: PropsWithChildren<any>) {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);
  const eventsService: EventsService = useContext<EventsService>(eventsServiceContext);

  const classes = useStyles();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const gutterStyles = useRowGutterStyles();

  return (
    <StaticQuery
      query={graphql`
        query Data {
          general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
            frontmatter {
              title
              logo
              description
              teaserMessages {
                message
              }
            }
          }
          legalAgreements: allMarkdownRemark(filter: {
            fileAbsolutePath: { regex: "/.*(legal-agreements).*/" },
            frontmatter: { isActive: { eq: true } } 
          }) {
            edges {
              node {
                frontmatter {
                  title
                  slug
                }
              }
            }
          }
          sortedCategories: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/.*(categories).*/" } }
            sort: { fields: frontmatter___position, order: ASC }
          ) {
            edges {
              node {
                frontmatter {
                  ref
                  title
                  slug
                  description
                  position
                  parentRef
                }
              }
            }
          }
          contact: markdownRemark(fileAbsolutePath: { regex: "/settings/contact.md$/" }) {
            frontmatter {
              stores {
                id
                descriptor
                address {
                  line1
                  line2
                  postalCode
                  city
                  country
                }
                googleMapsLink
              }
              email
              phoneNumber
              whatsappNumber
              facebookPage
              instagramPage
            }
          }
        }
      `}
      render={({
        contact,
        general,
        sortedCategories,
        legalAgreements,
      }) => {
        const {
          email,
          facebookPage,
          instagramPage,
          whatsappNumber,
          phoneNumber,
        } = contact.frontmatter;
        const { stores } = contact.frontmatter;

        const {
          title,
          logo,
          description,
          teaserMessages,
        } = general.frontmatter;

        const categories: ReadonlyArray<CategoryGraphQlResponse> = sortedCategories.edges.map(({ node }) => node.frontmatter)
          .filter(cat => cat.parentRef === null || cat.parentRef === undefined);

        const categoriesMap: Map<number, CategoryGraphQlResponse> = new Map(
          sortedCategories.edges.map(({ node: { frontmatter } }) => [frontmatter.ref, frontmatter])
        );

        const mappedCategories: ReadonlyArray<CategorySummary> = categories.map(cat => {
          return {
            ref: cat.ref,
            slug: cat.slug,
            title: cat.title,
            seoTitle: cat.seoTitle,
            parents: getCategoryParentsList(cat, categoriesMap)
              .map(cat => ({
                ref: cat.ref,
                title: cat.title,
                slug: cat.slug,
                parentRef: cat.parentRef,
              } as CategoryBase))
              .reverse()
          };
        });

        function onSideBarItemMenuClick(sidebar, setOpen, category: Category) {
          return (event: { preventDefault: () => void }) => {
            event.preventDefault();
            if (sidebar.primarySidebar.open) {
              setOpen('primarySidebar', false);
              navigate(`/${category.slug}/`);
            }
          };
        }

        return (
          <>
            <Helmet>
              <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
              <meta name="lang" content="fr" />
            </Helmet>
            <Root scheme={scheme} theme={theme}>
              {({
                state: { sidebar },
                setOpen
              }) => (
                <>
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  <SubHeader subheaderId={"subHeader"} className={classes.subHeaderBackground}>
                    <Container disableGutters className={classes.subHeaderContainer}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" paddingX={1} flexGrow={1} width="100%">
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <ExternalLink
                            title="Google Maps"
                            href={stores[0].googleMapsLink}
                            onClick={() => eventsService.handleGetDirections()}
                          >
                            <Box display="flex" justifyContent="center" alignItems="center">
                              <LocationOnIcon fontSize="small" />
                              <Typography variant="caption" component="p">
                                Localisation
                              </Typography>
                            </Box>
                          </ExternalLink>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" gridGap={4}>
                          <ExternalLink title="Facebook" href={facebookPage} onClick={() => eventsService.handleSocialLink()}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                              <FacebookIcon fontSize="small" />
                            </Box>
                          </ExternalLink>
                          <Divider variant="fullWidth" flexItem />
                          <ExternalLink title="Instagram" href={instagramPage} onClick={() => eventsService.handleSocialLink()}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                              <InstagramIcon fontSize="small" />
                            </Box>
                          </ExternalLink>
                        </Box>
                      </Box>
                    </Container>
                  </SubHeader>
                  <Header className={classes.header} elevation={1}>
                    <Toolbar>
                      <SidebarTrigger sidebarId="primarySidebar" color="inherit" />
                      <Container disableGutters>
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight={`${headerMdHeight}px`}>
                          <HeaderToolbar logoSrc={logo} title={title} categories={mappedCategories} />
                        </Box>
                      </Container>
                    </Toolbar>
                  </Header>
                  <DrawerSidebar sidebarId="primarySidebar">
                    <SidebarContent>
                      <List>
                        <ListSubheader>
                          {translationService.translate('SIDEBAR_CATEGORIES')}
                        </ListSubheader>
                        {categories.map((category: Category) => (
                          <ListItem
                            key={category.slug}
                            divider
                            button
                            onClick={onSideBarItemMenuClick(sidebar, setOpen, category)}
                          >
                            <ListItemText
                              primary={category.title}
                              primaryTypographyProps={{
                                component: 'span',
                                variant: 'h6',
                                color: 'textPrimary',
                              }}
                            />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={onSideBarItemMenuClick(sidebar, setOpen, category)}>
                                <ChevronRightIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </SidebarContent>
                  </DrawerSidebar>
                  <Content className={classes.content}>
                    {teaserMessages && teaserMessages.length > 0 && <TeaserMessage messages={teaserMessages.map(msg => msg.message)} />}
                    {props.children}
                  </Content>
                  <InsetFooter className={classes.footer}>
                    {/* defining spacing cause horizontal scroll problem */}
                    <Grid container direction="column" style={{ paddingTop: theme.spacing(5) }}>
                      <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start" spacing={3}>
                        <Grid item xs={12} sm={6} md>
                          <FooterItem title={translationService.translate('FOOTER_OUR_STORES')}>
                            <FooterInfo stores={stores} />
                          </FooterItem>
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                          <FooterItem title={translationService.translate('FOOTER_OUR_PRODUCTS')}>
                            <ul className={classes.unstyledUl}>
                              {categories.map((category: Category) => (
                                <li key={category.slug}>
                                  <Link to={`/${category.slug}/`} color="inherit">
                                    <Typography variant="body1" gutterBottom>
                                      {category.title}
                                    </Typography>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </FooterItem>
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                          <FooterItem title={translationService.translate('FOOTER_LEGAL_INFORMATION')}>
                            <ul className={classes.unstyledUl}>
                              {legalAgreements.edges.map(({
                                node: {
                                  frontmatter: {
                                    title,
                                    slug
                                  }
                                }
                              }) => (
                                <li key={slug}>
                                  <Link to={`/${slug}/`} color="inherit">
                                    <Typography variant="body1" gutterBottom>
                                      {title}
                                    </Typography>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </FooterItem>
                        </Grid>
                        <Grid item xs={12} sm={6} md>
                          <FooterItem title={translationService.translate('FOOTER_CONTACT_US')}>
                            <Grid container spacing={2}>
                              <Grid item container spacing={1} direction="column">
                                <Grid item>
                                  <Typography variant="body1">{translationService.translate('FOOTER_NEED_HELP')}</Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    disableElevation
                                    size="small"
                                    // TODO: What if null ?
                                    href={`https://wa.me/212540024047`}
                                    startIcon={<WhatsAppIcon />}
                                  >
                                    {translationService.translate('FOOTER_CONTACT_US_ON_WHATSAPP')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    // TODO: what if null
                                    variant="outlined"
                                    color="default"
                                    size="small"
                                    href={`tel:${phoneNumber}`}
                                    startIcon={<CallIcon />}
                                    disableElevation
                                  >
                                    {normalizeMoroccanPhoneNumber(phoneNumber)}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body1">
                                    Email:
                                    {' '}
                                    <Link href={`mailto:${email}`} color="inherit" itemProp="email">
                                      {email}
                                    </Link>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </FooterItem>
                        </Grid>
                      </Grid>
                      <Grid container item style={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2)
                      }} spacing={2} direction="column">
                        <Grid item>
                          <FooterItem title={translationService.translate('FOOTER_FOLLOW_US_AT')}>
                            <Box className={gutterStyles.parent}>
                              <ExternalLink className={classes.displayInlineBlock} title="Facebook" href={facebookPage} color="primary"
                                onClick={() => eventsService.handleSocialLink()}>
                                <FacebookIcon fontSize="large" />
                              </ExternalLink>
                              <ExternalLink className={classes.displayInlineBlock} title="Instagram" href={instagramPage} color="primary"
                                onClick={() => eventsService.handleSocialLink()}>
                                <InstagramIcon fontSize="large" />
                              </ExternalLink>
                            </Box>
                          </FooterItem>
                        </Grid>
                        <Grid item>
                          <Divider light />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" align="center">
                            © 2021
                            {' '}
                            {title}
                            .
                            {' '}
                            {translationService.translate('FOOTER_ALL_RIGHTS_RESERVED')}
                            .
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <div dangerouslySetInnerHTML={{
                            __html: "<!--- DO NOT EDIT - GlobalSign SSL Site Seal Code - DO NOT EDIT ---><table width=125 border=0 cellspacing=0 cellpadding=0 title=\"CLICK TO VERIFY: This site uses a GlobalSign SSL Certificate to secure your personal information.\" ><tr><td><span id=\"ss_img_wrapper_gmogs_image_125-50_en_dblue\"><a href=\"https://www.globalsign.com/\" target=\"_blank\" title=\"GlobalSign Site Seal\" rel=\"nofollow\"><img alt=\"SSL\" border=0 id=\"ss_img\" src=\"//seal.globalsign.com/SiteSeal/images/gs_noscript_125-50_en.gif\"></a></span><script type=\"text/javascript\" src=\"//seal.globalsign.com/SiteSeal/gmogs_image_125-50_en_dblue.js\"></script></td></tr></table><!--- DO NOT EDIT - GlobalSign SSL Site Seal Code - DO NOT EDIT --->"
                          }}/>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </InsetFooter>
                </>
              )}
            </Root>
          </>
        );
      }}
    />
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
