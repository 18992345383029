import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Markdown from '../../shared-components/markdown';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  message: {
    padding: theme.spacing(0.5),
  },
}));

interface Props {
  readonly messages: ReadonlyArray<string>;
}

const TeaserMessage: React.FC<Props> = ({ messages }) => {
  // Styles
  const classes = useStyles();

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (messages[currentMessageIndex + 1]) {
        setCurrentMessageIndex(currentMessageIndex + 1)
      } else if (messages[currentMessageIndex - 1]) {
        setCurrentMessageIndex(currentMessageIndex - 1)
      } else {
      }
    }, 3500);

    return () => clearInterval(interval);
  }, [currentMessageIndex]);

  return (

    <Box className={classes.container}>
      <Markdown className={classes.message}>{messages[currentMessageIndex]}</Markdown>
    </Box>

  );
};

export default TeaserMessage;
