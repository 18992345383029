import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby-theme-material-ui';

/**
 * https://www.gatsbyjs.org/docs/gatsby-link/#replace-a-tags-with-the-link-tag-for-local-links
 * https://web.dev/external-anchors-use-rel-noopener/
 */
const ExternalLink: React.FC<any> = (props: PropsWithChildren<any>) => (
  <Link color="inherit" {...props} target="_blank" rel="noopener">
    {props.children}
  </Link>
);

export default ExternalLink;
