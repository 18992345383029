import {Maybe} from 'true-myth';
import {Product, QuantitySize} from '../models/product';
import {Discounted} from '../models/discounted';
import {groupBy} from './operators';
import {CategoryGraphQlResponse} from "../../gatsby-node";

/**
 * A product is available for shipping only if it's eligible for delivery and at least one item
 * exists on the stock.
 * @param product
 */
export const isProductAvailableForShipping = (product: Product): boolean => product.eligibleForDelivery && product.totalQuantity > 0;

/**
 * Calculate current price when a discount is present or not
 * @param sellingPrice
 * @param discountedPrice
 */
export const calculateCurrentProductPrice = (sellingPrice: number, discountedPrice?: number) => (discountedPrice && discountedPrice > 0) ? discountedPrice : sellingPrice;

/**
 * A product is available in store only if it's eligible for sale in store and at least one item
 * exists on the stock.
 * @param product
 */
export const isProductAvailableInStore = (product: Product): boolean => product.eligibleForSaleInStore && product.totalQuantity > 0;

/**
 * Generate a map that contains available sizes per store
 * The key is the size and the value is a pair of storeId and size quantity
 * @param product
 */
export const getAvailableStockPerStore = (product: Product): Map<string, Map<number, number>> => new Map(
  Array.from(groupBy<QuantitySize, string>(product.quantityPerSize, (item: QuantitySize) => item.size)
    .entries())
    .map(
      (entry: [string, ReadonlyArray<QuantitySize>]) => [
        entry[0],
        new Map(
          entry[1].map((size: QuantitySize) => [size.storeId, size.quantity] as [number, number]),
        ),
      ],
    ),
);

/**
 * Generate street address based on line 1 and 2 (used for Schema.org)
 * @param line1
 * @param line2
 */
export const generatePostalAddressStreet = (line1: string, line2?: string) => {
  return `${line1}${line2 ? ` ${line2}` : ''}`;
}

export const isSelectedSizeAvailableForStore = (
  availableStockPerStore: Map<string, Map<number, number>>,
  selectedSize: string | undefined,
  storeId: number,
): boolean => {
  const sizeQuantity: number | undefined = selectedSize ? availableStockPerStore.get(selectedSize)
    ?.get(storeId) : undefined;
  return sizeQuantity ? sizeQuantity > 0 : false;
};

export function applyDiscount(initialPrice: number, discountedPrice?: number): Discounted | null {
  return Maybe.of(discountedPrice)
    .mapOr(null, (discounted: number) => (discounted > 0
      ? ({
        price: discountedPrice,
        rate: ((initialPrice - discounted) * 100) / initialPrice,
      } as Discounted)
      : null));
}

export function getCategoryParentsList(category: CategoryGraphQlResponse, categories: Map<number, CategoryGraphQlResponse>): ReadonlyArray<CategoryGraphQlResponse> {
  if (category && (category.parentRef !== undefined && category.parentRef !== null)) {
    return Array.from(categories.entries())
      .filter((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[0] !== category.ref)
      .filter((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[0] === category.parentRef)
      .map((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[1]);
  } else {
    return [];
  }
}

export function getCategoryChildrenList(category: CategoryGraphQlResponse, categories: Map<number, CategoryGraphQlResponse>): ReadonlyArray<CategoryGraphQlResponse> {
  if (category) {
    return Array.from(categories.entries())
      .filter((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[0] !== category.ref)
      .filter((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[1].parentRef === category.ref)
      .map((categoryEntry: [number, CategoryGraphQlResponse]) => categoryEntry[1])
      .sort((a, b) => (a.position > b.position ? -1 : 1))
  } else {
    return [];
  }
}
