import colors from '../assets/reference-data/colors.json';
import { TranslationService } from './translationService';
import {EntityLoader} from "./common/entityLoader";
import {EntityLister} from "./common/entityLister";

export interface ColorSearchCriteria {
  readonly identifiers: ReadonlyArray<string>;
}

export class ColorsService implements EntityLoader<string, Color>, EntityLister<Color, ColorSearchCriteria> {
  private translationService: TranslationService;

  public getTranslatedColorKey: (colorId: string) => string;

  findEntities(searchCriteria: ColorSearchCriteria): ReadonlyArray<Color> {
    return searchCriteria.identifiers
      .map((identifier: string) => colors[identifier])
      .filter((color: Color | undefined) => color !== undefined);
  }

  loadEntity(identifier: string): Color | null {
    return colors[identifier];
  }

  constructor(translationService: TranslationService) {
    this.translationService = translationService;

    this.getTranslatedColorKey = (colorId: string): string => {
      const color: Color | null = this.loadEntity(colorId);
      return color ? this.translationService.translate(`COLOR_${color.key}`) : colorId;
    };
  }
}

export interface Color {
  readonly id: string;
  readonly hex: string;
  readonly key: string;
}

export enum Colors {
  TRANSPARENT = '#66000000',
}
