import React from 'react';
import { fromFetch } from 'rxjs/fetch';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, NEVER, Observable, of } from 'rxjs';
import { ProductSummary } from '../models/product';
import { requireNonNull } from '../utils/operators';

export enum SortType {
  DEFAULT = "DEFAULT",
  LOWEST_PRICE = "LOWEST_PRICE"
}

export interface FilterSearchCriteria {
  readonly categories: ReadonlySet<string>;
  readonly sizes: ReadonlySet<string>;
}

export interface CategoryProducts {
  readonly containsAllProducts: boolean;
  readonly products: ReadonlyArray<ProductSummary>
}

export interface CategoryPageState {
  readonly categoriesItems: Map<string, CategoryProducts>;
  readonly categoryKey: string | null;
  readonly categoryCursor: number;
  readonly scrollYPosition: number | null;
  readonly categorySort: SortType;
  readonly categoryFilter: FilterSearchCriteria;
  readonly isFetching: boolean;
  readonly fetchAllCategoryItemsIfNeeded: () => Promise<void>;
  readonly updateCategoryFilter: (filterSearchCriteria: FilterSearchCriteria) => Promise<void>;
  readonly loadMore: () => Promise<void>;
  readonly handleCategoryChange: (newCategoryKey: string, categoryItems: ReadonlyArray<ProductSummary>, totalCategoryItems: number, filters?: FilterSearchCriteria) => void;
  readonly handleCategoryChangeBasedOnQueryParams: (newCategoryKey: string, filers: FilterSearchCriteria, totalCategoryItems: number) => Promise<void>;
  readonly setCategoryItems: (categoryKey: string, products: ReadonlyArray<ProductSummary>) => void;
  readonly setCategorySort: (categorySort: SortType) => void;
  readonly updateCategorySort: (categorySort: SortType) => Promise<void>;
  readonly setCategoryFilters: (filterSearchCriteria: FilterSearchCriteria) => void;
  readonly resetCategoryFilters: () => void;
  readonly resetCategorySort: () => void;
  readonly setScrollYPosition: (position: number | null) => void;
}

const INITIAL_CATEGORY_CURSOR = 1;

const initialCategoryPageState: CategoryPageState = {
  categoryKey: null,
  categoryCursor: INITIAL_CATEGORY_CURSOR,
  scrollYPosition: null,
  categoriesItems: new Map<string, CategoryProducts>(),
  categorySort: SortType.DEFAULT,
  categoryFilter: {
    sizes: new Set(),
    categories: new Set()
  },
  isFetching: false,
  fetchAllCategoryItemsIfNeeded: () => Promise.resolve(),
  updateCategoryFilter: (filterSearchCriteria: FilterSearchCriteria) => Promise.resolve(),
  loadMore: () => Promise.resolve(),
  handleCategoryChange: (newCategoryKey: string, categoryItems: ReadonlyArray<ProductSummary>, totalCategoryItems: number, filters?: FilterSearchCriteria) => undefined,
  handleCategoryChangeBasedOnQueryParams: (newCategoryKey: string, filers: FilterSearchCriteria, totalCategoryItems: number) => Promise.resolve(),
  setCategoryItems: (categoryKey: string, products: ReadonlyArray<ProductSummary>) => undefined,
  setCategorySort: (categorySort: SortType) => undefined,
  updateCategorySort: (categorySort: SortType) => Promise.resolve(),
  setCategoryFilters: (filterSearchCriteria: FilterSearchCriteria) => undefined,
  resetCategoryFilters: () => undefined,
  resetCategorySort: () => undefined,
  setScrollYPosition: (position: number | null) => undefined
};

const categoryPageActions = {
  INCREMENT_CATEGORY_CURSOR: 'INCREMENT_CATEGORY_CURSOR',
  SET_CATEGORY_ITEMS: 'SET_CATEGORY_ITEMS',
  TOGGLE_CATEGORY_NEW_ONLY_FILTER: 'TOGGLE_CATEGORY_NEW_ONLY_FILTER',
  CHANGE_CATEGORY_PAGE: 'CHANGE_CATEGORY_PAGE',
  CHANGE_CATEGORY_PAGE_WITH_PARAMS: 'CHANGE_CATEGORY_PAGE_WITH_PARAMS',
  SET_FETCHING: 'SET_LOADING',
  SET_CATEGORY_CURSOR: 'SET_CATEGORY_CURSOR',
  SET_CATEGORY_SORT: 'SET_CATEGORY_SORT',
  SET_CATEGORY_FILTERS: 'SET_CATEGORY_FILTERS',
  SET_SCROLL_Y_POSITION: 'SET_SCROLL_Y_POSITION'
};

function categoryReducer(state: CategoryPageState, action: StateAction): CategoryPageState {
  switch (action.type) {
    case categoryPageActions.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload.value,
      };
    case categoryPageActions.INCREMENT_CATEGORY_CURSOR:
      return {
        ...state,
        categoryCursor: state.categoryCursor ? state.categoryCursor + 1 : INITIAL_CATEGORY_CURSOR,
      };
    case categoryPageActions.CHANGE_CATEGORY_PAGE:
      return {
        ...state,
        categoryKey: action.payload.newCategoryKey,
        categorySort: SortType.DEFAULT,
        categoryFilter: {
          categories: new Set(),
          sizes: new Set()
        },
        categoriesItems: new Map([
          ...state.categoriesItems.entries(),
          [
            action.payload.newCategoryKey,
            {
              containsAllProducts: action.payload.totalCategoryItems <= action.payload.categoryItems.length,
              products: action.payload.categoryItems,
            }
          ]
        ])
      };
    case categoryPageActions.CHANGE_CATEGORY_PAGE_WITH_PARAMS:
      return {
        ...state,
        categoryKey: action.payload.newCategoryKey,
        categorySort: SortType.DEFAULT,
        categoryFilter: action.payload.filters,
      };
    case categoryPageActions.SET_CATEGORY_CURSOR:
      return {
        ...state,
        categoryCursor: action.payload.value
      };
    case categoryPageActions.SET_CATEGORY_SORT:
      return {
        ...state,
        categorySort: action.payload.value,
      };
    case categoryPageActions.SET_CATEGORY_FILTERS:
      return {
        ...state,
        categoryFilter: action.payload.value,
      };
    case categoryPageActions.SET_SCROLL_Y_POSITION:
      return {
        ...state,
        scrollYPosition: action.payload.value,
      };
    case categoryPageActions.SET_CATEGORY_ITEMS:
      return {
        ...state,
        categoriesItems: new Map([
          ...state.categoriesItems.entries(),
          [
            action.payload.valueKey,
            {
              containsAllProducts: true,
              products: action.payload.value
            }
          ]
        ])
      };
    default:
      return state;
  }
}

/**
 * The reason we use Global State instead of Component State is that
 * when the user clicks something on the main page and then clicks back,
 * we don't want to reset the user's scroll position. If we don't maintain
 * state, then we will "lose" some of the products when the user clicks
 * back and the state resets, which obviously resets scroll position as well.
 */
export const GlobalStateContext: React.Context<CategoryPageState> = React.createContext<CategoryPageState>(initialCategoryPageState);

export interface StateAction {
  readonly type: string;
  readonly payload?: any;
}

const fetchAllCategoryData = async (categoryKey: string): Promise<ReadonlyArray<ProductSummary>> => {
  try {
    const response = await fetch(`${__PATH_PREFIX__}/page-data/all-categories/${categoryKey.replace("/", "-")}.json`);
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    const products: ReadonlyArray<ProductSummary> = await response.json();
    return products;
  } catch (error) {
    console.error(error);
    // todo check
    return Promise.reject();
  }
}

export const GlobalState = ({ children }): JSX.Element => {
  const [state, dispatch] = React.useReducer(categoryReducer, initialCategoryPageState);

  const value: CategoryPageState = {
    categoryKey: state.categoryKey,
    categoryCursor: state.categoryCursor,
    scrollYPosition: state.scrollYPosition,
    categorySort: state.categorySort,
    categoryFilter: state.categoryFilter,
    categoriesItems: state.categoriesItems,
    isFetching: state.isFetching,
    fetchAllCategoryItemsIfNeeded: async () => {
      const categoryKey: string = requireNonNull(state.categoryKey);
      const categoryItems: CategoryProducts | undefined = value.categoriesItems.get(categoryKey);
      const doesNotContainsAllProducts = !(categoryItems && categoryItems.containsAllProducts);
      if (doesNotContainsAllProducts) {
        dispatch({
          type: categoryPageActions.SET_FETCHING,
          payload: {
            value: true
          }
        });
        const products = await fetchAllCategoryData(categoryKey);
        value.setCategoryItems(categoryKey, products);
        dispatch({
          type: categoryPageActions.SET_CATEGORY_CURSOR,
          payload: {
            value: 1
          }
        });
        dispatch({
          type: categoryPageActions.SET_FETCHING,
          payload: {
            value: false
          }
        });
      } else {
        return Promise.resolve();
      }
    },
    loadMore: async () => {
      const categoryKey: string = requireNonNull(state.categoryKey);
      const categoryItems: CategoryProducts | undefined = value.categoriesItems.get(categoryKey);
      const doesNotContainsAllProducts: boolean = !(categoryItems && categoryItems.containsAllProducts);

      if (doesNotContainsAllProducts) {
        dispatch({
          type: categoryPageActions.SET_FETCHING,
          payload: {
            value: true
          }
        });
        return fetchAllCategoryData(categoryKey)
          .then((products) => {
            value.setCategoryItems(categoryKey, products);
            dispatch({
              type: categoryPageActions.INCREMENT_CATEGORY_CURSOR
            });
            dispatch({
              type: categoryPageActions.SET_FETCHING,
              payload: {
                value: false
              }
            });
          });
      } else {
        dispatch({
          type: categoryPageActions.INCREMENT_CATEGORY_CURSOR
        });
        return Promise.resolve();
      }
    },
    updateCategoryFilter: async (filterSearchCriteria: FilterSearchCriteria) => {
      if (filterSearchCriteria.categories.size > 0 || filterSearchCriteria.sizes.size > 0) {
        await value.fetchAllCategoryItemsIfNeeded();
        value.setCategoryFilters(filterSearchCriteria);
      } else {
        value.setCategoryFilters(filterSearchCriteria);
        return Promise.resolve();
      }
    },
    handleCategoryChangeBasedOnQueryParams: async (newCategoryKey: string, filters: FilterSearchCriteria, totalCategoryItems: number) => {
      dispatch({
        type: categoryPageActions.SET_FETCHING,
        payload: {
          value: true
        }
      });
      return fetchAllCategoryData(newCategoryKey)
        .then((products) => {
          value.setCategoryItems(newCategoryKey, products);
          dispatch({
            type: categoryPageActions.CHANGE_CATEGORY_PAGE_WITH_PARAMS,
            payload: {
              newCategoryKey,
              totalCategoryItems,
              filters
            }
          });
          dispatch({
            type: categoryPageActions.SET_FETCHING,
            payload: {
              value: false
            }
          });
        });
    },
    handleCategoryChange: (newCategoryKey: string, categoryItems: ReadonlyArray<ProductSummary>, totalCategoryItems: number) => {
      dispatch({
        type: categoryPageActions.CHANGE_CATEGORY_PAGE,
        payload: {
          newCategoryKey,
          categoryItems,
          totalCategoryItems
        },
      });
      dispatch({
        type: categoryPageActions.SET_CATEGORY_CURSOR,
        payload: {
          value: INITIAL_CATEGORY_CURSOR
        },
      });
    },
    setCategoryItems: (categoryKey: string, products: ReadonlyArray<ProductSummary>) => {
      dispatch({
        type: categoryPageActions.SET_CATEGORY_ITEMS,
        payload: {
          valueKey: categoryKey,
          value: products,
        },
      });
    },
    setCategoryFilters: (filterSearchCriteria: FilterSearchCriteria) => {
      dispatch({
        type: categoryPageActions.SET_CATEGORY_FILTERS,
        payload: {
          value: filterSearchCriteria
        },
      });
    },
    setCategorySort: (sort: SortType) => {
      dispatch({
        type: categoryPageActions.SET_CATEGORY_SORT,
        payload: {
          value: sort,
        },
      });
    },
    setScrollYPosition: (position: number | null) => {
      dispatch({
        type: categoryPageActions.SET_SCROLL_Y_POSITION,
        payload: {
          value: position
        }
      })
    },
    updateCategorySort: async (sort: SortType) => {
      if (sort !== value.categorySort) {
        await value.fetchAllCategoryItemsIfNeeded();
        value.setCategorySort(sort);
      } else {
        return Promise.resolve();
      }
    },
    resetCategoryFilters: () => {
      dispatch({
        type: categoryPageActions.SET_CATEGORY_FILTERS,
        payload: {
          value: {
            categories: new Set(),
            sizes: new Set()
          } as FilterSearchCriteria
        },
      });
    },
    resetCategorySort: () => {
      dispatch({
        type: categoryPageActions.SET_CATEGORY_SORT,
        payload: {
          value: SortType.DEFAULT,
        },
      });
    }
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};
