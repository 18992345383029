import React, { PropsWithChildren } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const FooterItem: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {children}
    </>
  );
};

export default FooterItem;
