import React from 'react';
import {
  Grid, MenuList, Typography, useTheme,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'gatsby-theme-material-ui';
import LinkMenuItem from './linkMenuItem';
import { Category } from '../../models/category';

const useStyles = makeStyles((theme: Theme) => createStyles({
  smLogo: {
    marginLeft: '-48px',
  },
  menuList: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  logoText: {
    fontFamily: 'Georgia',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface Props {
  readonly logoSrc: string;
  readonly title: string;
  readonly categories: ReadonlyArray<Category>;
}

const HeaderToolbar: React.FC<Props> = ({ logoSrc, title, categories }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent={isDownSm ? 'center' : undefined}>
      <Grid item>
        <Link to="/">
          <img src={`/${logoSrc}`} alt={title} className={isDownSm ? classes.smLogo : undefined} />
        </Link>
      </Grid>
      {!isDownSm && (
        <Grid item>
          <MenuList className={classes.menuList} disablePadding>
            {categories.map((category: Category) => (
              <LinkMenuItem key={category.slug} to={`/${category.slug}/`} color="primaryText">
                <Typography component="span" variant="button" color="textPrimary">
                  {category.title}
                </Typography>
              </LinkMenuItem>
            ))}
          </MenuList>
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderToolbar;
