import React from "react";
import {GlobalState} from "./src/state/globalState";
import {QueryParamProvider} from "use-query-params";
import {ReachAdapter} from "use-query-params/adapters/reach";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({element}) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return (
        <QueryParamProvider adapter={ReachAdapter}>
            <GlobalState>{element}</GlobalState>
        </QueryParamProvider>
    );
};
