import { TranslationService } from './translationService';
import { Color, ColorsService } from './colorsService';
import { Product } from '../models/product';

export class MessagingService {
  private colorLoader: ColorsService;

  private translationService: TranslationService;

  public generateProductMessage: (product: Product, size: string, url: string) => string;

  constructor(colorLoader: ColorsService, translationService: TranslationService) {
    this.colorLoader = colorLoader;
    this.translationService = translationService;

    this.generateProductMessage = (product: Product, size: string, url: string): string => {
      const color: Color | null = product.colorId ? this.colorLoader.loadEntity(product.colorId.toString()) : null;
      const translatedColor: string | null = color ? this.translationService.translate(`COLOR_${color.key}`) : null;
      return encodeURIComponent(
        `Salamoalykum, je suis intéressé(e) par ce produit :\n${product.title} (Réf. ${product.reference})${
          translatedColor ? `\nCouleur : ${translatedColor}` : ''
        }\nTaille : ${size}\n${url}`,
      );
    };
  }
}
