/**
 * Useful links
 * Facebook pixels: https://developers.facebook.com/docs/meta-pixel/reference
 * GA4: https://developers.google.com/analytics/devguides/collection/ga4/reference/config
 */
export class EventsService {

  public handleWhatsappCheckout: (productId: string, productTitle: string, productVariant: string) => void;

  public handleCallCheckout: () => void;

  public handleGetDirections: () => void;

  public handleSocialLink: () => void;

  constructor() {
    this.handleWhatsappCheckout = (productId: string, productTitle: string, productVariant: string): void => {
      EventsService.whenGtagAndFbq(() => {
        window.gtag('event', 'begin_checkout', {
          method: 'whatsapp',
          items: [
            {
              id: productId,
              name: productTitle,
              variant: productVariant,
            },
          ],
        });
        window.fbq('track', 'InitiateCheckout', {
          content_name: productTitle,
          // TODO: get currency
          currency: 'MAD',
          num_items: 1,
          content_type: 'product',
          content_ids: [`${productId}-${productVariant}`],
        });
      })
    };

    this.handleCallCheckout = (): void => {
      EventsService.whenGtagAndFbq(() => {
          window.gtag('event', 'generate_lead', {});
          window.fbq('track', 'Contact', {
            method: 'telephone'
          });
        }
      );
    };

    this.handleGetDirections = (): void => {
      EventsService.whenGtagAndFbq(() => {
        window.gtag('event', 'get_directions', {});
        window.fbq('track', 'GetDirections', {});
      })
    };

    this.handleSocialLink = (): void => {
      EventsService.whenGtagAndFbq(() => {
        window.gtag('event', 'social_link', {});
        window.fbq('track', 'SocialLink', {});
      })
    };
  }

  private static whenGtagAndFbq(runnable: () => void): void {
    if (process.env.NODE_ENV === 'production' && typeof window.gtag === 'function' && typeof window.fbq === 'function') {
      runnable();
    }
  }
}
