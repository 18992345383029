export function range(start: number, end: number): ReadonlyArray<number> {
  const length: number = end - start;
  return Array.from({ length }, (_, index: number) => start + index);
}

export function groupBy<T, K extends keyof any>(list: ReadonlyArray<T>, keySelector: (item: T) => K): Map<K, T[]> {
  const map: Map<K, T[]> = new Map();
  list.forEach((item) => {
    const key: K = keySelector(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 * Return true if there is a difference between the two lists, false if not
 */
export function isTheSame<T>(before: ReadonlyArray<T>, after: ReadonlyArray<T>): boolean {
  if (before.length !== after.length)
    return true;
  return before.filter(b => after.includes(b)).length !== after.length;
}

export function requireNonNull<T>(x: T | null | undefined): T {
  if (x === null || x === undefined) {
    throw new Error("Value cannot be null or undefined");
  }
  return x;
}