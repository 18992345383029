import { createTheme, Theme } from '@material-ui/core/styles';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#e56332',
    },
    secondary: {
      main: '#f0ebde',
    },
  },
});

export default theme;
