import React, {useContext} from 'react';
import {Grid, Typography, useTheme} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ExternalLink from '../../shared-components/externalLink';
import {StoreDetails} from '../../templates/product-details/productDetails';
import {generatePostalAddressStreet} from "../../utils/general";
import {EventsService} from "../../services/eventsService";
import {eventsServiceContext} from "../../services/provider";

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const FooterInfo: React.FC<{ stores: StoreDetails[] }> = ({stores}) => {
  // Styles
  const theme: Theme = useTheme();
  const classes = useStyles();

  const eventsService: EventsService = useContext<EventsService>(eventsServiceContext);

  return (
    <Grid item container spacing={2}>
      {stores.map((store: StoreDetails) => (
        <Grid item key={store.id}>
          <Typography variant="body1">
            {store.descriptor}
            <br/>
            <ExternalLink
              to={store.googleMapsLink}
              onClick={() => eventsService.handleGetDirections()}
              className={classes.link}
            >
              {generatePostalAddressStreet(store.address.line1, store.address.line2)}
              <br/>
              {store.address.postalCode}
              <span> </span>
              {store.address.city}
              <br/>
              {store.address.country}
            </ExternalLink>
          </Typography>
          {/* <Typography variant="body1">
              <span>{translationService.translate("FOOTER_OPENING_HOURS")}<br/>Lundi-Jeudi, Samedi 10:30–13:30 — 16:00-21:00<br/>Vendredi 10:30–12:45 — 16:00-21:00
                <meta itemProp="openingHours" content="Mo-Th 10:30-13:30"/>
                <meta itemProp="openingHours" content="Mo-Th 16:00-21:00"/>
                <meta itemProp="openingHours" content="Fr 10:30-12:45"/>
                <meta itemProp="openingHours" content="Fr 16:00-21:00"/>
                <meta itemProp="openingHours" content="Sa 10:30-13:30"/>
                <meta itemProp="openingHours" content="Sa 16:00-21:00"/>
              </span>
            </Typography> */}
        </Grid>
      ))}
    </Grid>
  );
};

export default FooterInfo;
