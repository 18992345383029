import React from 'react';
import { TranslationService } from './translationService';
import { MessagingService } from './messagingService';
import { ColorsService } from './colorsService';
import { EventsService } from './eventsService';

const translationService: TranslationService = new TranslationService();
const colorService: ColorsService = new ColorsService(translationService);
const messagingService: MessagingService = new MessagingService(colorService, translationService);
const eventsService: EventsService = new EventsService();

export const translationServiceContext = React.createContext<TranslationService>(translationService);
export const messagingServiceContext = React.createContext<MessagingService>(messagingService);
export const colorServiceContext = React.createContext<ColorsService>(colorService);
export const eventsServiceContext = React.createContext<EventsService>(eventsService);
